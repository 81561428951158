<template>
  <access-ag-home-content />
</template>

<script>
import AccessAgHomeContent from '../components/AccessAgHomeContent'

export default {
    name: 'Home',

    components: {
        AccessAgHomeContent,
    },
}
</script>
